@import "./../../sass/variables.scss";
@import "./../../sass/mixins.scss";

.shippingInfo {
  @include sectionSize;
  background-image: url("./../../img/bg.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    width: 100%;
    align-items: start;
  }
  &__content {
    width: 60%;
    height: 60%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
      height: 75%;
      margin-top: 25px;
    }

    & h4 {
      font-size: 22px;
      color: $white;
    }
  }
}
