@import "./../../sass/variables.scss";
@import "./../../sass/mixins.scss";

.rateDelivery {
  @include sectionSize;
  @include centerContent;

  @media (max-width: 900px) {
    width: 100%;
    padding: 40px 0;
  }

  &__image img {
    @include imageSize;
  }

  &__stars {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    @include sectionTitle(30%);
  }
  &__button {
    margin-top: 30px;
  }
}
