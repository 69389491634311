@import "./../../sass/variables.scss";

.shippingDetails {
  width: 380px;
  height: 100%;
  background: $white;
  margin-top: 20px;
  border-radius: 20px;
  box-shadow: $shadow-lg;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  position: relative;
  &__gif {
    margin-top: 30px;
    width: 100px;
    height: 100px;
  }
  &::before {
    position: absolute;
    content: "";
    background: $white;
    opacity: 0.8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    top: 100%;
    left: (50%);
    transform: translateX(-50%);
    width: 75%;
    height: 10px;
  }
  &__title {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    padding: 10px 0;
    & h5 {
      margin-left: 10px;
      color: $blue;
      font-size: 18px;
    }
  }

  @media (max-width: 900px) {
    width: 300px;
    padding: 20px;
  }
}
