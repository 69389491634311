@import "./../../sass/variables.scss";
@import "./../../sass/mixins.scss";

.shippingDetail {
  display: flex;
  height: 40px;
  width: 100%;
  &__title {
    width: 40%;
    @include shippingDetailContent;
    & img {
      width: 17px;
      height: 17px;
    }

    & span {
      font-size: 13px;
      color: $blue;
      margin-left: 15px;
      font-weight: 600;
    }
  }
  &__content {
    width: 60%;
    @include shippingDetailContent;

    & p {
      font-size: 13px;
      color: $green;
    }
  }
}
