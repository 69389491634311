@import "./variables.scss";

@mixin shippingDetailContent {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

@mixin centerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin sectionTitle($width) {
  & h4 {
    font-size: 22px;
    margin-top: 10px;
    width: $width;
    text-align: center;
    & span {
      color: $green;
    }
  }
}

@mixin sectionSize {
  width: 50%;
  height: 100%;
}

@mixin imageSize {
  width: 120px;
  height: 120px;
}
