@import "./../../sass/variables.scss";

.navbar {
  width: 100%;
  height: 80px;
  padding: 5px 100px;
  display: flex;
  align-items: center;
  background: white;
  box-shadow: $shadow-sm;

  &__image {
    height: 70%;
  }
}
