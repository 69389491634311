@import "./../../sass/variables.scss";

.button {
  background: $green;
  border-radius: 30px;
  padding: 10px 20px;
  color: white;
  border: none;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
  &--transparent {
    background: transparent;
    border: 2px solid $green;
    color: $green;
    font-weight: 600;
  }
}
