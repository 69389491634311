@import "./../../sass/mixins.scss";

.feedBackForm {
  @include sectionSize;
  @include centerContent;
  &__image img {
    @include imageSize;
  }
  &__title {
    display: flex;
    justify-content: center;
    @include sectionTitle(60%);
  }
  &__text {
    margin-top: 15px;
  }
  &__text p {
    font-size: 13px;
    font-weight: 700;
    & span {
      font-weight: 400;
    }
    @media (max-width: 900px) {
      text-align: center;
    }
  }
  &__form {
    display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 2em;
      &:focus {
        outline: none;
        border: 2px solid $green;
      }
   
    &__buttons {
      width: 180px;
      display: flex;
      justify-content: space-between;
      margin: 2em auto 0 auto;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    padding: 40px 0;
  }
}
