*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}
