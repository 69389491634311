.content {
  display: flex;
  width: 100%;
  height: calc(100vh - 80px);
  &__image {
    width: 200px;
    height: 200px;
    margin-top: 15%;
    margin-left: 18%;
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
}
